import useSoundLibrary from "use-sound";
import { soundSetting as serviceSoundSetting } from "service";
import {
  GameOver,
  SoundClickButton,
  SoundDuplicate,
  SoundGreenLine,
  SoundOrangeTile,
  SoundOrangeTileDelay,
  CricketSoundSuspense777,
  CricketStrikeSound,
  CricketCrowdBoo,
  CricketSoundWin,
  CountUpWin,
  JackpotSound,
  SwooshSound,
  TripleSevenLastHit,
  SideBetWinner,
  CricketBallThrow,
  CricketBallsFillingHopper,
  CricketSportRunningSound,
  CricketCrowdCheer,
  CricketBatImpact,
} from "assets/audio";
import { createEmptySoundStub } from "service/sound";

export const useSound = (soundEnabled) => {
  const soundSetting = {
    ...serviceSoundSetting,
    soundEnabled
  };
  // const
  const [playSuspense777, { stop: stopSuspense777 }] = useSoundLibrary(CricketSoundSuspense777, { ...soundSetting, loop: true });
  const [playSoundOrangeTile] = useSoundLibrary(SoundOrangeTile, soundSetting);
  const [playSoundClickButton] = useSoundLibrary(SoundClickButton, soundSetting);
  const [playSoundOrangeTileDelay] = useSoundLibrary(
    SoundOrangeTileDelay,
    soundSetting
  );
  const [playSoundOrangeClick] = useSoundLibrary(CricketBatImpact, soundSetting);
  const playSoundRolling = createEmptySoundStub();
  const stopSoundRolling = createEmptySoundStub();
  const [playSoundDuplicate] = useSoundLibrary(SoundDuplicate, soundSetting);
  const [playSideBetWinner] = useSoundLibrary(SideBetWinner, soundSetting);
  const [playSoundGreenLine] = useSoundLibrary(SoundGreenLine, soundSetting);
  const [playSoundStrike] = useSoundLibrary(CricketStrikeSound, soundSetting);
  const [playSoundBoo] = useSoundLibrary(CricketCrowdBoo, soundSetting);
  const [playSoundWin] = useSoundLibrary(CricketSoundWin, soundSetting);

  const [playCountUpWin] = useSoundLibrary(CountUpWin, soundSetting);
  const [playGameOver] = useSoundLibrary(GameOver, soundSetting);
  const [playJackpotSound] = useSoundLibrary(JackpotSound, soundSetting);
  const [playSwooshSound] = useSoundLibrary(SwooshSound);
  const [playWinSound] = useSoundLibrary(TripleSevenLastHit, soundSetting);
  const [playBallThrow] = useSoundLibrary(CricketBallThrow, soundSetting);
  const [playBatImpact] = useSoundLibrary(CricketBatImpact, soundSetting);
  const [playCrowdCheer, { stop: stopCrowdCheer }] = useSoundLibrary(CricketCrowdCheer, soundSetting);
  const [playBallsFillingHopper, { stop: stopPlayBallsFillingHopper }] = useSoundLibrary(CricketBallsFillingHopper, soundSetting);
  const [playSportRunningSound] = useSoundLibrary(CricketSportRunningSound, soundSetting);
  const playOut = createEmptySoundStub();

  return {
    playSuspense777,
    stopSuspense777,
    playSoundOrangeTile,
    playSoundClickButton,
    playSoundOrangeTileDelay,
    playSoundOrangeClick,
    playSoundRolling,
    stopSoundRolling,
    playSoundDuplicate,
    playSideBetWinner,
    playSoundGreenLine,
    playSoundStrike,
    playSoundBoo,
    playCountUpWin,
    playGameOver,
    playJackpotSound,
    playSwooshSound,
    playTripleSevenLastStrike: () => {},
    playBallThrow,
    playBatImpact,
    playBallsFillingHopper,
    stopPlayBallsFillingHopper,
    playSportRunningSound,
    playCrowdCheer,
    stopCrowdCheer,
    playWinSound,
    playSoundWin,
    playOut
  };
}

export default useSound